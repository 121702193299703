const en = {
    HEADER_MENU_1_SUB_1 : "Core value",
    HEADER_MENU_1_SUB_2 : "Mission",
    HEADER_MENU_1_SUB_3 : "Logo",
    HEADER_MENU_1_SUB_4 : "Keyword",
    HEADER_MENU_1_SUB_5 : "Essence",
    HEADER_MENU_1_SUB_6 : "Tagline",
    HEADER_MENU_2_SUB_1 : "Chungdam",
    HEADER_MENU_2_SUB_2 : "Majangdong",
    HEADER_MENU_2_SUB_3 : "Shibuya SOHO",
    HEADER_MENU_2_SUB_4 : "Hongdae Pocha",
    HEADER_MENU_2_SUB_4_SIMPLE : "Hongdae",
    HEADER_MENU_2_SUB_5 : "Ssal factory",
    HEADER_MENU_3_SUB_1 : "We Are",
    HEADER_MENU_3_SUB_2 : "One Chick",
    HEADER_MENU_3_SUB_3 : "We Want",
    HEADER_BOTTOM_TEXT : "Stories in Every Flavor,\nMemories In Every Bite",
    MAIN_TOP_TEXT : "CJONE is a Korean cuisine-focused restaurant brand that originated in the UK. We leverage our extensive experience and expertise to deliver the authentic taste and charm of our dishes in diverse ways. By adhering to strict principles and standards, we maintain a stable brand operation and consistently provide high-quality products and services. With a people-centered approach, we prioritize building trust with our customers and continuously strive to deliver a satisfying experience.",
    MAIN_TOP_TEXT_MOBILE : "CJONE is a Korean cuisine-focused restaurant brand that originated in the UK. We leverage our extensive experience and expertise to deliver the authentic taste and charm of our dishes in diverse ways.",
    CONTACT_US_BTN : "CONTACT US",
    MAIN_VISION : "BRAND VISION",
    MAIN_VISION_TEXT : "We maintain stable brand operations through strict principles and standards, ensuring consistent quality and service for our customers. We prioritize customer trust, placing the highest importance on people-centered values, and strive to deliver a consistently satisfying experience.",
    MAIN_ESSENCE : "BRAND ESSENCE",
    MAIN_ESSENCE_TEXT : "We maintain stable brand operations through strict principles and standards, ensuring consistent quality and service for our customers. We prioritize customer trust, placing the highest importance on people-centered values, and strive to deliver a consistently satisfying experience.",
    MAIN_LOOKBOOK : "LOOKBOOK",
    MAIN_LOOKBOOK_1_TITLE : "7 Sub-Brands Owned",
    MAIN_LOOKBOOK_1_TEXT : "CJONE has successfully launched seven sub-brands, including café, dining, cupbap, and barbecue, all centered around Korean cuisine. Through these various concepts, we deliver the charm of a broad range of Asian culinary experiences to our customers.",
    MAIN_LOOKBOOK_2_TITLE : "Over 10 Years of Brand Operation",
    MAIN_LOOKBOOK_2_TEXT : "With more than 10 years of experience, CJONE has established a stable operation in the UK restaurant industry, based on accumulated expertise and adherence to strict principles and standards. We ensure consistent quality and reliability in every experience we provide.",
    MAIN_LOOKBOOK_3_TITLE : "Over 200 Menu Items",
    MAIN_LOOKBOOK_3_TEXT : "CJONE offers a diverse selection of over 200 menu items, including Korean cuisine, as well as Japanese and East Asian dishes. Each dish is crafted with the passion and expertise that CJONE is known for, guaranteeing a satisfying experience for every customer.",
    MAIN_LOOKBOOK_4_TITLE : "More than 80 Employees",
    MAIN_LOOKBOOK_4_TEXT : "CJONE employs over 80 staff members, from marketing managers to highly skilled kitchen managers. We are committed to investing in talent, continually supporting the growth and development of our team members to deliver the highest level of service.",
    MAIN_BOTTOM_TITLE : "The Essence Of Korean Cuisine",
    MAIN_BOTTOM_TEXT : "CJONE aims to go beyond merely serving food; we want to share the deep culture and values of Korean cuisine and contribute to fostering warm relationships through communication with people. We hope that customers will feel the charm of Korean cuisine through their experience at CJONE and develop a desire to share it with others.",
    ABOUT_BANNER_SUB_TEXT_1 : "CJONE always strives to communicate with customers with honesty and sincerity.",
    ABOUT_BANNER_SUB_TEXT_2 : "Our brand maintains consistent standards across all locations.",
    ABOUT_BANNER_SUB_TEXT_3 : "Empathy with customers reflects our people-centered values.",
    ABOUT_MISSION : "BRAND MISSION",
    ABOUT_MISSION_TEXT : "We capture the essence of cuisine to offer our customers delicious memories and unique experiences. Grounded in trust and dedication, we provide rich cultural experiences and create moments that leave a lasting impression in the memories of our customers.",
    ABOUT_TAGLINE : "BRAND TAGLINE",
    ABOUT_BRAND_STORY : "BRAND STORY",
    ABOUT_ONE_SPIRIT : "ONE SPIRIT",
    ABOUT_ONE_SPIRIT_TEXT : `We approach everything with the spirit of "ONE." The phrase "to be ONE" represents our commitment to consistency and an integrated approach, striving for the best results in all our activities. It embodies our values of continuously working to meet and exceed customer expectations.`,
    ABOUT_ONE_VALUE_1 : "VALUE",
    ABOUT_ONE_VALUE_1_TEXT : "Our cuisine reflects essential values and deep flavors. We prioritize recipes and unique tastes, respecting the history and cultural characteristics of each dish. By utilizing meticulously crafted recipes and techniques, we aim to preserve the authenticity and unique flavors of our dishes, delivering the allure of food to our customers. This way, we maintain traditional values while providing an exceptional dining experience.",
    ABOUT_ONE_VALUE_2 : "PRINCIPLE",
    ABOUT_ONE_VALUE_2_TEXT : `Our brand adheres to strict principles and standards. The "ONE Principle" ensures that every decision and action reflects our core values, providing consistent service. We use fresh, selected ingredients to accurately reproduce the flavors of traditional Korean cuisine, ensuring consistent taste through thorough quality control. In customer service, we prioritize a friendly and professional approach, quickly reflecting customer needs and their feedback. Our goal is to provide reliable quality and satisfaction with every visit.`,
    ABOUT_ONE_VALUE_3 : "EFFICIENCY",
    ABOUT_ONE_VALUE_3_TEXT : `We strive for seamless system operation. Through precise cooking and service procedures, we maintain consistent quality, optimize raw materials and personnel to minimize waste, and ensure a safe dining environment through rigorous hygiene management. We support all employees in understanding clear roles and responsibilities and executing tasks consistently. Regular training and feedback enhance professionalism, while open communication and collaboration foster a positive corporate culture. This allows us to provide the best experience for customers and a satisfying working environment for employees.`,
    ABOUT_ONE_VALUE_4 : "RELATIONSHIP",
    ABOUT_ONE_VALUE_4_TEXT : `We value relationships, maintaining positive connections with customers based on authenticity and trust. We cherish each customer, forming warm relationships through personalized service and thoughtful considerations. Additionally, we strengthen teamwork based on cooperation and respect among employees, providing sincere hospitality and a comfortable atmosphere for all customers. Our people-centered approach fosters long-term, trustworthy relationships with customers, actualising the brand's values.`,
    ABOUT_ONE_VALUE_5 : "VISION",
    ABOUT_ONE_VALUE_5_TEXT : `Through years of successes and failures, we have confirmed the infinite potential of Korean cuisine. Based on the confidence gained from our dedicated efforts, we plan to expand multiple locations within the UK. Through various sub-brands with unique characteristics, we will showcase the diverse allure and essence of Asian cuisine in the UK. This will enable us to widely promote excellent flavors and provide customers with special and cherished dining experiences.`,
    ABOUT_ONE_VALUE_6 : "SPIRIT",
    ABOUT_ONE_VALUE_6_TEXT : `We aim to preserve the essence of Korean cuisine while combining innovative ideas and a modern sensibility to establish ourselves as a leading Asian food brand in the UK market. Our long-term vision is to enhance the global recognition of Asian cuisine and to widely promote it through sustainable growth and global expansion.`,
    ABOUT_SLOGAN : "BRAND SLOGAN",
    BRANCH_BANNER_TITLE : "Join the\nCJONE family!",
    BRANCH_BANNER_SUB_TITLE : "Join us in bringing authentic Korean cuisine to a wider audience while achieving your business goals. Don't miss this opportunity to partner with a trusted brand and thrive in the exciting world of Korean dining.",
    BRANCH_BANNER_SCROLL_BTN : "Franchise Inquiry",
    BRANCH_BANNER_AREA_1 : "Chungdam",
    BRANCH_BANNER_AREA_2 : "Majangdong",
    BRANCH_BANNER_AREA_3 : "Shibuya SOHO",
    BRANCH_BANNER_AREA_4 : "Hongdae Pocha",
    BRANCH_BANNER_AREA_5 : "Ssal factory",
    BRANCH_GO_TO_WEB : "Go to web",
    BRANCH_MARKET_1_TITLE : "Chungdam",
    BRANCH_MARKET_1_SUB_TITLE : "Korean restaurant",
    BRANCH_MARKET_1_TEXT : "Chung'dam is a high-end Korean restaurant located in London's Soho, inspired by the sophisticated culinary atmosphere of Seoul's Cheongdam-dong. It offers a variety of dishes using traditional Korean techniques and fresh ingredients, highlighting premium meats, as well as cuts that are not often seen in Korean barbecues; enjoying traditional Korean meals, and meats expertly prepared and grilled by trained staff members provides a professional and unique service for a more relaxed dining experience.\nThe restaurant also offers the Pyeonbaek Steam Box that steams fresh meat, seafood and vegetables in a fragrant hinoki (cypress) box that aids in healthy eating practices.\nFeaturing a karaoke room, it creates a circular environment of luxury that blends traditional Korean flavours with a modern playful twist, making it popular for those seeking an upscale Korean dining experience within Soho.",
    BRANCH_MARKET_2_TITLE : "Majangdong",
    BRANCH_MARKET_2_SUB_TITLE : "Korean barbecue",
    BRANCH_MARKET_2_TEXT : "Majangdong is a Korean barbecue restaurant specialising in various meats that are displayed out in the open; inspired by the Majangdong meat market in Seoul, it’s known for offering fresh cuts and a variety of Korean dishes.\nCustomers can enjoy a more casual dining experience, offering traditional Korean stews, noodles and rice dishes  to enjoy with the meats picked right from the fridge.",
    BRANCH_MARKET_3_TITLE : "Shibuya SOHO",
    BRANCH_MARKET_3_SUB_TITLE : "Japanese home cafe",
    BRANCH_MARKET_3_TEXT : `Shibuya Soho accentuates a wide range of home-made Japanese desserts and hot meals. This serene cafe accommodates Udon, Curries and Teriyakis, extending to our specialised desserts such as fluffy cakes, croffles and other sweet treats that pair perfectly with our home roasted coffees and seasonal soft drinks. Packaged into a modern and minimalist interior, it creates a cozy and trendy atmosphere.`,
    BRANCH_MARKET_4_TITLE : "Hongdae Pocha",
    BRANCH_MARKET_4_SUB_TITLE : "Korean Dining Pub",
    BRANCH_MARKET_4_TEXT : `Hongdae Pocha is a speakeasy dining pub located in soho that offers traditional Korean street foods and drinks, hotpots and soju, canapés and rice wines, all in a vibrant and young environment. Enjoying the new, as well as old school Kpop music, it provides an atmosphere for a laid-back meeting spot for old friends, and new`,
    BRANCH_MARKET_5_TITLE : "Ssal factory",
    BRANCH_MARKET_5_SUB_TITLE : "Korean Takeaway",
    BRANCH_MARKET_5_TEXT : `Ssal (‘Rice’) Factory offers a modern reinterpretation of traditional Korean cuisine, focusing especially on rice-based dishes. The restaurant features creative sides and appetisers that elevate classic Korean flavours in a refined manner. Signature dishes like deop bab and kimbab are the main attraction, served in a relaxed and contemporary setting, making it an ideal spot for enjoying traditional Korean food with a modern touch.`,
    BRANCH_CONTACT_US_TITLE : "Contact Us",
    BRANCH_CONTACT_US_SUB_TITLE : "Inquire about franchising with CJONE today!\nBy becoming part of the CJONE family,",
    BRANCH_CONTACT_TABS_1 : "Franchise",
    BRANCH_CONTACT_TABS_2 : "Other",
    BRANCH_CONTACT_SUBMIT : "Submit",
    PEOPLE_BANNER : "We strengthen internal teamwork and customer relationships based on mutual respect, creating an environment where everyone feels valued and respected.",
    PEOPLE_TITLE_1 : 'TRUST AND\nTRANSPARENCY',
    PEOPLE_TITLE_1_TEXT : "Trust forms the foundation of teamwork, which is a crucial element that extends to our customers. Through honest and transparent communication, we strengthen the bonds among our staff.",
    PEOPLE_TITLE_2 : 'GROWTH AND\nDEVELOPMENT',
    PEOPLE_TITLE_2_TEXT : "We provide continuous training and education to help everyone reach their full potential and ensure that all employees align with the brand's values and vision.",
    PEOPLE_TITLE_3 : 'TEAMWORK AND\nCOLLABORATION',
    PEOPLE_TITLE_3_TEXT : "We provide continuous training and education to help everyone reach their full potential and ensure that all employees align with the brand's values and vision.",
    PEOPLE_CEO_TITLE : "CEO'S MESSAGE",
    PEOPLE_CEO_TEXT : "At CJ ONE, our most important asset is our people. We believe that individual growth is closely linked to the success of our brand. By fostering an environment of support and collaboration, we are committed to empowering our employees to thrive both personally and professionally. Additionally, we are dedicated to sustainable management practices to create a positive impact on our communities, striving to establish ourselves as a brand that all employees can take pride in. CJ ONE is committed to building a better future through continuous challenges and innovations.",
    PEOPLE_CEO_NAME : "Jay Choi",
    PEOPLE_CEO_CATEGORY : "Chief Executive Officer",
    PEOPLE_MEMBER : "OUR MEMBER",
    PEOPLE_MEMBER_1 : "Duk Hoon Kim",
    PEOPLE_MEMBER_1_CATEGORY : "Chief Operating Officer",
    PEOPLE_MEMBER_2 : "Myung Jin Jun",
    PEOPLE_MEMBER_2_CATEGORY : "Store Director",
    PEOPLE_MEMBER_3 : "Min Jae Lee",
    PEOPLE_MEMBER_3_CATEGORY : "Chief Administrative Officer",
    PEOPLE_MEMBER_4 : "Mohammed Lal Miah",
    PEOPLE_MEMBER_4_CATEGORY : "Head Chef",
    PEOPLE_MEMBER_5 : "Sung Jae Lee",
    PEOPLE_MEMBER_5_CATEGORY : "Operations Manager",
    PEOPLE_MEMBER_6 : "Zico Ng Hao Yi",
    PEOPLE_MEMBER_6_CATEGORY : "Chief Financial Officer",
}

export default en;