const ko = {
    HEADER_MENU_1_SUB_1 : "브랜드 핵심가치",
    HEADER_MENU_1_SUB_2 : "브랜드 미션",
    HEADER_MENU_1_SUB_3 : "브랜드 로고",
    HEADER_MENU_1_SUB_4 : "브랜드 키워드",
    HEADER_MENU_1_SUB_5 : "브랜드 에센스",
    HEADER_MENU_1_SUB_6 : "브랜드 태그라인",
    HEADER_MENU_2_SUB_1 : "청담",
    HEADER_MENU_2_SUB_2 : "마장동",
    HEADER_MENU_2_SUB_3 : "시부야 소호",
    HEADER_MENU_2_SUB_4 : "홍대포차",
    HEADER_MENU_2_SUB_4_SIMPLE : "홍대포차",
    HEADER_MENU_2_SUB_5 : "쌀팩토리",
    HEADER_MENU_3_SUB_1 : "We Are",
    HEADER_MENU_3_SUB_2 : "브랜드 원칙",
    HEADER_MENU_3_SUB_3 : "ONE합니다",
    // HEADER_BOTTOM_TEXT : "Stories in Every Flavor,\nMemories in Every Bite",
    MAIN_TOP_TEXT : "CJONE은 영국에서 시작한 한식 중심 요식업 브랜드입니다.\n풍부한 경험과 전문성을 바탕으로 다양한 방식으로 정통 한국 요리의 맛과 매력을 전달합니다.\n우리는 엄격한 원칙과 기준을 준수함으로써 안정적인 브랜드 운영을 유지하고, 일관된 고품질의 제품과 서비스를 제공합니다. 사람 중심의 접근 방식을 통해 고객과의 신뢰를 구축하는 것을 최우선으로 하며, 항상 만족스러운 경험을 제공하기 위해 지속적으로 노력합니다.",
    MAIN_TOP_TEXT_MOBILE : "CJONE은 영국에서 시작한 한식 중심 요식업 브랜드입니다.\n우리는 풍부한 경험과 전문성을 바탕으로 다양한 방식으로 정통 한국 요리의 맛과 매력을 전달합니다.",
    CONTACT_US_BTN : "문의하기",
    MAIN_VISION : "브랜드 비전",
    MAIN_VISION_TEXT : "CJONE은 아시아 요리, 특히 한국 음식을 세계에 소개하는 것을 목표로 합니다. 우리는 고객과의 신뢰를 구축하고, 고객 경험을 향상시키며, 진정한 관계를 형성하기 위해 '진정성, '일관성', '사람중심'의 핵심 가치를 강조합니다.",
    MAIN_ESSENCE : "브랜드 에센스",
    MAIN_ESSENCE_TEXT : "우리는 엄격한 원칙과 기준을 통해 안정적인 브랜드 운영을 유지하며, 고객에게 일관된 품질과 서비스를 보장합니다. 우리는 고객의 신뢰를 최우선으로 하며, 사람 중심의 가치를 가장 중요하게 생각하고, 항상 만족스러운 경험을 제공하기 위해 노력합니다.",
    // MAIN_LOOKBOOK : "LOOKBOOK",
    MAIN_LOOKBOOK_1_TITLE : "7개의 서브 브랜드 보유",
    MAIN_LOOKBOOK_1_TEXT : "CJONE은 10년동안 한식을 중심으로 까페, 다이닝, 컵밥, 바베큐 등 다양한 요식업 브랜드를 성공적으로 런칭했습니다.",
    MAIN_LOOKBOOK_2_TITLE : "10년이상 브랜드 운영",
    MAIN_LOOKBOOK_2_TEXT : "지난 10년동안 영국현지의 요식산업의 경험과 노하우를 바탕으로 철저한 원칙과 기준을 통해 안정적인 브랜드 운영을 유지하고 있습니다.",
    MAIN_LOOKBOOK_3_TITLE : "200개 넘는 메뉴구성",
    MAIN_LOOKBOOK_3_TEXT : "한식을 비롯해서 일식 및 동남아 퓨전음식 까지 CJONE만의 실력과 정성을 담은 메뉴들로 항상 만족스러운 경험을 드리고 있습니다.",
    MAIN_LOOKBOOK_4_TITLE : "80명이상의 직원보유",
    MAIN_LOOKBOOK_4_TEXT : "전문가들로 구성된 마케팅 매니저들과 최고의 실력을 갖춘 키친매니저들까지, 저희는 인재를 위한 투자를 아끼지 않습니다.",
    MAIN_BOTTOM_TITLE : "The Essence Of Korean Cuisine",
    MAIN_BOTTOM_TEXT : "CJONE은 단순히 음식을 제공하는 것을 넘어, 한국 음식의 깊은 문화와 가치를 알리고, 사람들과의 소통을 통해 따뜻한 관계를 형성하는 데 기여하고자 합니다. 우리는 고객이 CJONE에서의 경험을 통해 한국 요리의 매력을 느끼고, 이를 함께 나누고 싶어하는 마음을 갖게 되기를 바랍니다.",
    ABOUT_BANNER_SUB_TEXT_1 : "CJONE은 항상 정직하고 진실된 태도로 고객과 소통합니다.",
    ABOUT_BANNER_SUB_TEXT_2 : "우리 브랜드는 모든 지점에서 일관된 기준을 유지합니다.",
    ABOUT_BANNER_SUB_TEXT_3 : "고객과의 공감은 사람 중심 가치를 의미합니다.",
    ABOUT_MISSION : "브랜드 미션",
    ABOUT_MISSION_TEXT : "우리는 요리의 정수를 살려, 고객들에게 맛있는 추억과 특별한 경험을 제공합니다. 신뢰와 정성을 바탕으로 풍부한 문화적 경험을 선사하며 고객의 기억에 남는 순간을 만들어가는 것이 우리의 사명입니다.",
    ABOUT_TAGLINE : "브랜드 태그라인",
    ABOUT_BRAND_STORY : "브랜드 스토리",
    ABOUT_ONE_SPIRIT : "ONE 합니다.",
    ABOUT_ONE_SPIRIT_TEXT : `우리 브랜드는 모든 일에 대해 'ONE'의 정신을 가지고 접근합니다. 'ONE한다'는 일관성과 통합된 접근 방식을 통해 모든 활동에서 최고의 결과를 추구하며, 고객의 기대를 충족시키기 위해 끊임없이 노력하는 우리의 가치를 대표하는 말입니다.`,
    ABOUT_ONE_VALUE_1 : "래의 가치에 충실합니다.",
    ABOUT_ONE_VALUE_1_TEXT : "우리의 요리는 본질적인 가치와 깊은 풍미를 그대로 재현합니다. 조리법과 고유의 맛을 중요시하며, 각 요리가 가진 역사와 문화적 특성을 존중합니다. 우리는 정성스럽게 다듬어진 레시피와 기법을 활용해, 요리의 진정성과 고유한 풍미를 최대한 살리며, 고객에게 음식의 매력을 온전히 전달합니다. 이렇게 우리는 전통적 가치를 현대적 감각으로 보존하며, 최고의 식사 경험을 제공합니다.",
    ABOUT_ONE_VALUE_2 : "칙을 지킵니다.",
    ABOUT_ONE_VALUE_2_TEXT : `우리 브랜드는 철저한 원칙과 기준을 준수합니다. 'ONE칙'은 단순히 규칙을 넘어, 모든 결정과 행동이 우리의 핵심 가치를 반영하도록 함으로써 일관성 있는 서비스를 제공합니다. 신선하고 엄선된 재료를 사용하여 전통 한식의 맛을 정확히 재현하며, 철저한 품질 관리를 통해 일관된 맛을 보장합니다. 고객 서비스에서도 최상의 경험을 제공하기 위해 친절하고 전문적인 대응을 원칙으로 하며, 고객의 요구와 피드백을 신속하게 반영합니다. 이를 통해 매 방문마다 신뢰할 수 있는 품질과 만족을 드리는 것이 우리의 목표입니다.`,
    ABOUT_ONE_VALUE_3 : "활하게 운영합니다.",
    ABOUT_ONE_VALUE_3_TEXT : `우리는 'ONE활한 시스템 운영' 을 위해 늘 노력하고 있습니다. 정교한 조리 및 서비스 절차를 통해 일관된 품질을 유지하며, 원자재와 인력을 최적화하여 자원의 낭비를 최소화하고, 철저한 위생 관리로 안전한 식사 환경을 보장합니다. 또한, 모든 직원이 명확한 역할과 책임을 이해하고 일관되게 업무를 수행하도록 지원합니다. 정기적인 교육과 피드백을 통해 전문성을 높이고, 열린 소통과 협력을 통해 긍정적인 사내 문화를 조성합니다. 이로써 고객에게 최고의 경험을 제공하고, 직원들에게도 만족스러운 근무 환경을 만들어갑니다.`,
    ABOUT_ONE_VALUE_4 : "만한 관계를 유지합니다.",
    ABOUT_ONE_VALUE_4_TEXT : `우리는 관계를 소중히 여기며, 진정성과 신뢰를 바탕으로 고객과 원만한 관계를 유지합니다. 고객 한 분 한 분을 소중히 여기며, 개인 맞춤형 서비스와 세심한 배려로 따뜻한 관계를 형성합니다. 또한, 직원 간의 협력과 존중을 바탕으로 팀워크를 강화하고, 모든 고객에게 진심 어린 환대와 편안한 분위기를 제공합니다. 사람 중심의 접근으로 고객과의 장기적이고 신뢰할 수 있는 관계를 유지하며, 브랜드의 가치를 실현해 나갑니다.`,
    ABOUT_ONE_VALUE_5 : "대하게 생각합니다.",
    ABOUT_ONE_VALUE_5_TEXT : `우리 브랜드는 수년간의 실패와 성공을 통해 한식의 무한한 가능성을 확인했습니다. 그동안 쏟아온 노력과 정성으로 얻은 자신감을 바탕으로 영국 내에서 여러 지점 확장 계획을 가지고 있습니다. 우리는 각기 다른 특성을 가진 서브 브랜드들을 통해 영국에서 아시아 요리의 다양한 매력과 진수를 선보일 것입니다. 이를 통해 뛰어난 맛과 풍미를 널리 알리고, 고객들에게 특별하고 소중한 식사 경험을 제공할 것입니다.`,
    ABOUT_ONE_VALUE_6 : "합니다.",
    ABOUT_ONE_VALUE_6_TEXT : `우리는 한식의 본질을 보존하면서도 혁신적인 아이디어와 현대적인 감각을 결합하여, 영국 시장에서 대표적인 아시아 푸드 브랜드로 자리매김 하고자 합니다. 우리의 장기적인 비전은 아시아 요리의 세계적 인지도를 높이고, 지속 가능한 성장과 글로벌 확장을 통해 이를 전 세계에 널리 알리는 것입니다.`,
    ABOUT_SLOGAN : "브랜드 슬로건",
    BRANCH_BANNER_TITLE : "CJONE의 가족이 되세요!",
    BRANCH_BANNER_SUB_TITLE : "정통 한국 요리를 더 많은 사람들에게 선보이며 비즈니스 목표를 달성하는 기회를 놓치지 마세요. 믿을 수 있는 브랜드와 함께 성장할 수 있는 이 기회를 잡으세요.",
    BRANCH_BANNER_SCROLL_BTN : "프랜차이즈 문의",
    // BRANCH_BANNER_AREA_1 : "Chungdam",
    // BRANCH_BANNER_AREA_2 : "Majangdong",
    // BRANCH_BANNER_AREA_3 : "Shibuya SOHO",
    // BRANCH_BANNER_AREA_4 : "Hongdae Pocha",
    // BRANCH_BANNER_AREA_5 : "Ssal factory",
    // BRANCH_MARKET_1_TITLE : "Chungdam",
    BRANCH_GO_TO_WEB : "웹사이트",
    BRANCH_MARKET_1_SUB_TITLE : "한식 전문점",
    BRANCH_MARKET_1_TEXT : "청담은 런던 소호에 위치한 고급 한국 레스토랑으로, 서울 청담동의 세련된 요리 분위기에서 영감을 받았습니다. 전통 한국 기법과 신선한 재료를 사용하여 다양한 요리를 제공하며, 그 중에서도 향긋한 히노키(편백) 상자에서 고기, 해산물, 채소를 찌는 편백 스팀 박스가 하이라이트입니다. 이 레스토랑은 프리미엄 바비큐 옵션과 노래방을 갖추고 있어 전통 한국의 맛을 현대적인 감각으로 조화롭게 결합한 고급스러운 환경을 만들어, 소호에서 고급 한국 다이닝 경험을 원하는 사람들에게 인기를 끌고 있습니다.",
    // BRANCH_MARKET_2_TITLE : "Majangdong",
    BRANCH_MARKET_2_SUB_TITLE : "한국 바베큐 식당",
    BRANCH_MARKET_2_TEXT : "마장동은 삼겹살과 목살 등 고기를 전문으로 하는 바비큐 레스토랑입니다. 서울의 마장동 정육시장에서 시작된 이곳은 신선한 고기 부위와 다양한 한국 요리를 제공하는 것으로 유명합니다. 고객들은 전통 한국식 찌개인 된장찌개와 김치찌개를 즐길 수 있으며, 고기를 직접 구우는 대신 직원들이 전문적인 그릴 서비스를 제공하여 보다 편안한 식사 경험을 제공합니다.",
    // BRANCH_MARKET_3_TITLE : "Shibuya SOHO",
    BRANCH_MARKET_3_SUB_TITLE : "일식 디저트&까페",
    BRANCH_MARKET_3_TEXT : `런던 소호 지역에 위치한 "시부야 카페"는 다양한 디저트와 식사를 제공하는 일본식 카페입니다. 이 카페의 시그니처 메뉴는 오레오, 딸기, 망고, 팥 등 다양한 토핑이 올라간 푹신한 빙수인 한국식 빙수입니다. 젊은 고객들 사이에서 특히 인기가 많습니다. 또한 카페에서는 스시, 돈카츠 커리, 테리야끼, 우동 등의 메뉴도 제공합니다. 현대적이고 미니멀한 인테리어가 아늑하고 세련된 분위기를 만들어냅니다.`,
    // BRANCH_MARKET_4_TITLE : "Hongdae Pocha",
    BRANCH_MARKET_4_SUB_TITLE : "길거리 음식 바(포장마차)",
    BRANCH_MARKET_4_TEXT : `홍대 포차는 런던의 로밀리 스트리에 위치해 있으며, 한국의 길거리 음식 바(포차)의 활기찬 분위기를 영국에 선보이고 있습니다. 친구들과 함께 캐주얼한 한국 음식과 음료를 즐기며 K-pop 음악을 들을 수 있는 완벽한 장소입니다. 메뉴에는 볶음 닭간, 매운 소라, 불고기 버섯 찌개와 같은 전통적인 요리가 포함되어 있으며, 모두 한국 소주와 잘 어울립니다. 특히 모임이나 편안한 분위기 속에서 정통 한국의 맛을 즐기고자 하는 사람들에게 인기가 많습니다.`,
    // BRANCH_MARKET_5_TITLE : "Ssal factory",
    BRANCH_MARKET_5_SUB_TITLE : "현대식 고급컵밥 전문점",
    BRANCH_MARKET_5_TEXT : `쌀 팩토리는 전통 한국 요리를 현대적으로 재해석한 레스토랑으로, 특히 밥을 기반으로 한 요리에 중점을 두고 있습니다. 이 레스토랑은 클래식한 한국의 맛을 세련되게 발전시킨 창의적인 사이드와 전채 요리를 제공합니다. 대표 메뉴인 비빔밥은 주요 매력으로, 편안하면서도 현대적인 분위기에서 제공되어 전통 한국 음식을 현대적인 감각으로 즐기기에 이상적인 장소입니다.`,
    BRANCH_CONTACT_US_TITLE : "문의하기",
    BRANCH_CONTACT_US_SUB_TITLE : "CJONE에 프랜차이즈 문의를 하세요!\nCJONE 가족이 되시면 많은 이득을 누릴 수 있습니다.",
    BRANCH_CONTACT_TABS_1 : "프랜차이즈",
    BRANCH_CONTACT_TABS_2 : "기타문의",
    BRANCH_CONTACT_SUBMIT : "제출하기",
    PEOPLE_BANNER : "우리는 관계를 소중히 여기며 직원 간의 협력과 존중을 바탕으로 팀워크를 강화하고, 모든 고객에게 진심 어린 환대와 편안한 분위기를 제공합니다. ",
    PEOPLE_TITLE_1 : '신뢰와 투명성',
    PEOPLE_TITLE_1_TEXT : "우리는 모든 직원이 서로를 믿고 의지할 수 있는 환경을 만들기 위해 노력합니다. 신뢰는 팀워크의 기반이 되며, 이는 고객에게도 전해지는 중요한 요소입니다. 우리는 정직하고 투명한 소통을 통해 직원들 간의 유대감을 강화하고 있습니다.",
    PEOPLE_TITLE_2 : '성장과 발전',
    PEOPLE_TITLE_2_TEXT : "CJ ONE은 직원들이 개인적 및 전문적으로 성장할 수 있는 기회를 제공합니다. 우리는 각자의 잠재력을 최대한 발휘할 수 있도록 지속적인 교육과 훈련을 지원하며, 모든 직원이 브랜드의 가치와 비전을 공유하도록 합니다.",
    PEOPLE_TITLE_3 : '팀워크와 협력',
    PEOPLE_TITLE_3_TEXT : "우리는 서로의 강점을 존중하고, 협력하여 문제를 해결하는 문화를 조성합니다. 팀원 간의 협업은 최고의 고객 경험을 제공하기 위한 필수 요소입니다. 서로 다른 아이디어와 의견을 소중히 여기며, 혁신적인 솔루션을 찾기 위해 노력합니다.",
    PEOPLE_CEO_TITLE : "CEO의 말",
    PEOPLE_CEO_TEXT : "CJ ONE에서 가장 중요한 자산은 우리의 사람들입니다. 우리는 개인의 성장과 브랜드의 성공이 밀접하게 연결되어 있다고 믿습니다. 지원과 협력의 환경을 조성함으로써, 직원들이 개인적으로나 직업적으로 성장할 수 있도록 지원하는 데 최선을 다하고 있습니다. 또한, 지속 가능한 경영 관행에 헌신하여 지역 사회에 긍정적인 영향을 미치기 위해 노력하고 있으며, 모든 직원이 자부심을 느낄 수 있는 브랜드로 자리 잡고자 합니다. CJ ONE은 지속적인 도전과 혁신을 통해 더 나은 미래를 건설하기 위해 노력하겠습니다.",
    PEOPLE_CEO_NAME : "최재일",
    PEOPLE_CEO_CATEGORY : "대표",
    PEOPLE_MEMBER : "멤버소개",
    PEOPLE_MEMBER_1 : "김덕훈",
    PEOPLE_MEMBER_1_CATEGORY : "최고 운영 책임자",
    PEOPLE_MEMBER_2 : "전명진",
    PEOPLE_MEMBER_2_CATEGORY : "매장 이사",
    PEOPLE_MEMBER_3 : "이민재",
    PEOPLE_MEMBER_3_CATEGORY : "최고 행정 책임자",
    PEOPLE_MEMBER_4 : "Mohammed Lal Miah",
    PEOPLE_MEMBER_4_CATEGORY : "헤드셰프",
    PEOPLE_MEMBER_5 : "이성재",
    PEOPLE_MEMBER_5_CATEGORY : "운영 관리자",
    PEOPLE_MEMBER_6 : "Zico Ng Hao Yi",
    PEOPLE_MEMBER_6_CATEGORY : "최고 재무 책임자",
}

export default ko;